/* Ensure the body and html take full height */
@font-face {
    font-family: 'archane';
    src: url('../assets/fonts/archane/archane.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html, body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
    font-family: archane, sans-serif;
    overflow-x: hidden;
}

/* Keep the menu at the top */
.menu {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #333;
    color: #cab776;
    padding: 15px;
    text-align: center;
    z-index: 10;
}

/* Apply the background to the body for full coverage */
body.hidden-page-body {
    background-image: url('../assets/images/hiddenBackground.png'); /* Replace with your background image */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed; /* Ensures it covers the full viewport */
}

/* Main container for the hidden page */
.hidden-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    text-align: center;
    color: white;
    padding: 5vh 5vw;
    box-sizing: border-box;
}

/* Scoped animations and styles to the hidden-page class */
.hidden-page h1 {
    font-size: 5vw; /* Responsive size for headings */
    margin: 20px 0;
    animation: rainbowText 3s linear infinite, pulseText 2s ease-in-out infinite, floatText 5s ease-in-out infinite;
    max-width: 90%;
    text-align: center;
}

/* Add padding around the paragraphs and center them */
.hidden-page p {
    padding: 10px;
    font-size: 1.5vw; /* Responsive text size */
    max-width: 90%;
    color: white;
    margin: 0 auto;
    text-align: center;
}

/* Fun and celebratory text animation scoped to the hidden page */
@keyframes rainbowText {
    0% { color: #FF0000; }
    14% { color: #FF7F00; }
    28% { color: #FFFF00; }
    42% { color: #00FF00; }
    57% { color: #0000FF; }
    71% { color: #4B0082; }
    85% { color: #8B00FF; }
    100% { color: #FF0000; }
}

@keyframes pulseText {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes floatText {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.confetti-piece {
    position: absolute;
    top: -50px;
    width: 10px;
    height: 10px;
    background-color: var(--confetti-color, red); /* Or any color */
    animation: confettiFall 5s ease-in-out infinite;
}

@keyframes confettiFall {
    0% {
        transform: translateY(-100px) rotate(0deg);
    }
    100% {
        transform: translateY(100vh) rotate(360deg);
    }
}


/* Media Queries */

/* For mobile devices (max-width: 600px) */
@media only screen and (max-width: 600px) {
    .hidden-page h1 {
        font-size: 6vw; /* Larger headings on mobile */
    }

    .hidden-page p {
        font-size: 3.5vw; /* Larger text for readability */
    }

    .hidden-page {
        padding: 10vh 5vw; /* Adjust padding for mobile devices */
    }
}

/* For tablets (min-width: 601px and max-width: 1024px) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .hidden-page h1 {
        font-size: 4vw; /* Smaller headings for tablets */
    }

    .hidden-page p {
        font-size: 2vw;
    }

    .hidden-page {
        padding: 8vh 5vw;
    }
}
