@font-face {
  font-family: 'archane';
  src: url('../assets/fonts/archane/archane.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'archane', sans-serif; /* Apply custom font */
  background-image: url('../assets/images/background.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.navbar, .content, .contact-form, .about-me {
  font-family: 'archane', sans-serif; /* Apply custom font to these specific elements */
}


/* Custom navbar styles */
.custom-navbar {
  background-color: rgba(0, 0, 0, 0.5); /* Your preferred background color */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;
}

.custom-navbar .logo {
  max-height: 60px; /* Adjust logo size */
}

.nav-links a {
  color: white; /* Text color */
  font-size: 1.2rem;
  margin-left: 100px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #cab776; /* Hover color */
}

.navbar-toggler {
  background-color: #fff; /* Optional: Change the color of the hamburger icon */
}

@media (max-width: 991px) {
  .custom-navbar {
    padding: 0 3vw;
  }

  .nav-links a {
    margin-left: 0;
    margin-bottom: 10px;
  }
}


/* Adjusting the logo size */
.logo {
  height: 10vh;
  width: auto;
  max-height: 100%;
  margin-left: 5vw;
}

.about-me-photo {
  max-width: 100%; /* Ensures the image doesn't exceed the container's width */
  height: auto; /* Maintains aspect ratio */
  max-height: 300px; /* You can adjust the height to whatever suits your design */
  margin-bottom: 20px;
}


.about-me {
  text-align: center;
  color: black;
  padding: 5vh 5vw;
  margin-top: 5vh;
}

/* Center nav links */
.nav-links-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-right: 10vw;
  gap: 5vw;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 1.5vw;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #cab776;
}

.content {
  text-align: center;
  color: white;
  padding: 10vh 5vw;
}

/* Contact form styling */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: white;
}

.contact-form h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 1.2vw;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1.2vw;
  border: none;
  border-radius: 5px;
}

textarea {
  height: 100px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #cab776;
  border: none;
  color: white;
  font-size: 1.5vw;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #cab776;
}

/* Media queries for responsiveness */

/* Mobile devices (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .logo {
    height: 8vh;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .nav-links {
    flex-direction: column;
    margin-right: 0;
    gap: 20px;
  }

  .nav-links a {
    font-size: 4vw;
  }

  .about-me-photo {
    height: 25vh;
  }

  .contact-form {
    max-width: 90%;
  }

  .content {
    padding: 10vh 5vw;
  }

  h1 {
    font-size: 5vw;
  }

  button {
    font-size: 4vw;
  }
}

/* Tablets (min-width: 601px and max-width: 1024px) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .navbar {
    padding: 0 5vw;
  }

  .logo {
    height: 8vh;
    margin-left: 5vw;
  }

  .nav-links {
    gap: 4vw;
    margin-right: 10vw;
  }

  .nav-links a {
    font-size: 2vw;
  }

  .about-me-photo {
    height: 28vh;
  }

  .contact-form {
    max-width: 80%;
  }

  .content {
    padding: 8vh 5vw;
  }

  h1 {
    font-size: 4vw;
  }
}

/* Larger screens (min-width: 1025px) */
@media only screen and (min-width: 1025px) {
  .navbar {
    padding: 0 10vw;
  }

  .logo {
    height: 10vh;
  }

  .nav-links {
    gap: 3vw;
  }

  .nav-links a {
    font-size: 1.5vw;
  }

  .about-me-photo {
    height: 30vh;
  }

  .contact-form {
    max-width: 600px;
  }

  .content {
    padding: 10vh 5vw;
  }

  h1 {
    font-size: 3vw;
  }

  button {
    font-size: 2vw;
  }
}


.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0; /* Reduce padding to make it less thick */
  position: fixed; /* Change from fixed to relative */
  bottom: 0;
  width: 100%;
  margin-top: auto; /* Pushes footer to the bottom without overlapping content */
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.instagram-logo {
  width: 30px; /* Smaller logo */
  height: 30px;
  filter: brightness(0) invert(1); /* Makes the Instagram logo white */
  transition: transform 0.3s;
}

.instagram-logo:hover {
  transform: scale(1.1);
}

.footer p {
  margin: 0;
  font-size: 12px; /* Slightly smaller font */
}

.hidden-page-logo {
  width: 30px;
  height: 30px;
  margin-left: 15px;
  transition: transform 0.3s;
}

.hidden-page-logo:hover {
  transform: scale(1.2);
}


